import { css } from '@nfront/global-styles';

const globalStyles = css`
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  :root {
    --firstActiveColor: hsl(220, 78%, 34%) !important;
    --secondActiveColor: #b03f92 !important;
    --superLightGray: #ccc !important;
    --textColor: #191919 !important;
    --lightTextColor: rgb(19, 65, 155) !important;
    --superLightTextColor: #aaa !important;
    --linkColor: #0074d9 !important;
    --hoverLinkColor: #0074d9 !important;
    --scrollBarThumb: #eaeaea !important;
    --scrollBarTrack: #f9f9f9 !important;
    --scrollBarWidth: 5px !important;
  }

  .css-sw4why {
    max-width: 850px !important;
  }

  table {
    margin: 15px 0 !important;
    padding: 0;
  }
  table tr {
    border-top: 1px solid #cccccc;
    background-color: white;
    margin: 0;
    padding: 0;
  }
  table tr:nth-child(2n) {
    background-color: #f8f8f8;
  }
  table tr th {
    font-weight: bold;
    border: 1px solid #cccccc;
    text-align: left;
    margin: 0;
    padding: 6px 13px;
  }
  table tr td {
    border: 1px solid #cccccc;
    text-align: left;
    margin: 0;
    padding: 6px 13px;
  }
  table tr th :first-child,
  table tr td :first-child {
    margin-top: 0;
  }
  table tr th :last-child,
  table tr td :last-child {
    margin-bottom: 0;
  }
`;

export default globalStyles;
