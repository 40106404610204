import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import path from 'path';

// eslint-disable-next-line import/no-mutable-exports
let appInsights = null;

const createMetricsMonitoring = async () => {
  const url = path.resolve(`/config.json`);
  console.log('Loading config: ' + url);
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`HTTP error: Status ${response.status}`);
  }
  const postsData = await response.json();
  if (postsData.telemetry || false) {
    console.log('Enable telemetry');
    /*
      process.env.GATSBY_APP_INSIGHTS => development mode ->  .env.development
      "((GATSBY_APP_INSIGHTS))" => use on build => replace by sed in gitlab.yml for QA and PROD
    */
    const instrumentationKey =
      process.env.GATSBY_APP_INSIGHTS || '0e7e31d9-f4a9-4e4d-9e3c-665f25c374b8'; //TODO: clean per env value

    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey,
        enableAutoRouteTracking: true,
        disableFetchTracking: true,
      },
    });
    appInsights.loadAppInsights();
  }
};

const trackPageView = pageName => {
  if (appInsights) {
    appInsights.trackPageView(pageName);
  }
};

const trackFeedbackPage = (pageName, isUseful) => {
  if (appInsights) {
    appInsights.trackEvent(
      { name: 'isUseful' },
      {
        page: pageName,
        isUseful,
      }
    );
  }
};

export { createMetricsMonitoring, trackPageView, trackFeedbackPage };
